import PropTypes from 'prop-types';

import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';
import bindPropsHOC from 'core/components/bindProps';

import Page from 'core/components/Page';
import { Indent } from 'core/components/Wrappers';

import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';

import RubricHeader from 'site/components/RubricHeader';
import SectionNews from 'site/components/SectionNews';
import Reviews from 'site/components/Reviews';
import MoreArticles from 'site/components/MoreArticles';
import Paginator from 'site/components/Paginator';
import Skeleton from 'site/components/SectionNews/Skeleton';

import dataProvider, { CHINESE_ARTICLES_LIMIT } from './chineseTopics';


function ChineseCarsPage(props) {
  const {
    loading,
    isMobile,
    kitayskieObzory,
    allArticles,
    tag,
  } = props;

  const {
    kitayskieObzoryVSlidere,
    kitayskiyObzorOdin,
    otherKitayskieObzory,
    count: allObzoryCount,
  } = kitayskieObzory || {};

  const {
    kitayskayaStatya,
    chineseArticles: {
      topics: chineseTopics,
      count: chineseCount,
    },
    count: allArticlesCount,
  } = allArticles || { chineseArticles: {} };

  const {
    meta_description: metaDescription,
  } = tag?.data?.attributes || {};

  const indent = isMobile ? '32rem' : '80rem';

  return (
    <Page
      title='Китайский автопром'
      description={metaDescription}
    >
      <RubricHeader
        title='Китайский автопром'
      />
      <Indent bottom={indent} />
      {loading && <Skeleton />}
      {!loading && (
        <>
          <Indent bottom={indent} />
          <Reviews content={{ topics: kitayskieObzoryVSlidere, count: allObzoryCount }} />
          <Indent bottom={indent} />
          <SectionNews
            content={{ topics: kitayskiyObzorOdin.concat(otherKitayskieObzory), count: allObzoryCount }}
            sectionTitle='Обзоры автомобилей'
            link='/reviews'
          />
          <Indent bottom={indent} />
          <MoreArticles
            fullWidthTopic={kitayskayaStatya[0]}
            articles={chineseTopics}
            count={allArticlesCount}
            sectionTitle='Статьи'
            link='/articles'
          />
          <Indent bottom={indent} />
          <Paginator
            apiParams={{
              topic_type: 'article',
              tag_slug: 'chinese-cars',
              exclude_ids: kitayskayaStatya.map(topic => topic.id)[0],
            }}
            startOffset={CHINESE_ARTICLES_LIMIT}
            filteredCount={chineseCount}
          />
          <Indent bottom={isMobile ? '48rem' : '80rem'} />
        </>
      )}
    </Page>
  );
}

const topicModel = modelPropTypes(topicAttributes);

const contentContract = PropTypes.shape({
  topics: PropTypes.arrayOf(topicModel),
  count: PropTypes.number,
});

ChineseCarsPage.propTypes = {
  isMobile: PropTypes.bool,
  kitayskieObzory: PropTypes.exact({
    kitayskieObzoryVSlidere: PropTypes.arrayOf(topicModel),
    kitayskiyObzorOdin: PropTypes.arrayOf(topicModel),
    otherKitayskieObzory: PropTypes.arrayOf(topicModel),
    count: PropTypes.number,
  }),
  loading: PropTypes.bool,
  allArticles: PropTypes.exact({
    count: PropTypes.number,
    chineseArticles: contentContract,
    kitayskayaStatya: PropTypes.arrayOf(topicModel),
  }),
  tag: PropTypes.object,
};

const Loader = bindPropsHOC({ loading: true })(ChineseCarsPage);

export default withPageHocs(dataProvider, Loader)(withBreakpoint(ChineseCarsPage));
