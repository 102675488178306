import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import Section from 'site/components/Section';
import SectionHeader from 'site/components/SectionHeader';

import DesktopTestDrives from './Desktop';
import MobileTestDrives from './Mobile';


const requiredPayloadImports = [
  'test_drive_meta', 'image', 'authors',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'list_headline',
  'announce',
];

function TestDrives({ content, isMobile }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onActiveChange = useCallback(({ realIndex }) => setActiveIndex(realIndex), []);

  const {
    topics,
    count,
  } = content;

  if (!count) return null;

  const TestDrivesBreakpoint = isMobile ? MobileTestDrives : DesktopTestDrives;

  return (
    <Section {...!isMobile && { withBackground: true }}>
      <SectionHeader link='/test-drive' count={count}>
        Тест-драйвы
      </SectionHeader>
      <Indent bottom={isMobile ? '32rem' : '80rem'} />
      <TestDrivesBreakpoint
        content={topics}
        activeIndex={activeIndex}
        onActiveChange={onActiveChange}
      />
    </Section>
  );
}

TestDrives.propTypes = {
  content: PropTypes.shape({
    topics: PropTypes.array,
    count: PropTypes.number,
  }),
  isMobile: PropTypes.bool,
};

const EnhancedTestDrives = withBreakpoint(TestDrives);

EnhancedTestDrives.requiredPayloadFields = requiredPayloadFields;
EnhancedTestDrives.requiredPayloadImports = requiredPayloadImports;

export default EnhancedTestDrives;
