import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';
import Link from 'core/components/Link';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import Texts from 'core/components/Texts';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import bindPropsHOC from 'core/components/bindProps';
import withMenu from 'core/components/withMenu';

import PVPButtons from 'site/components/PVPButtons';
import BlueRectangleWrapper from 'site/components/BlueRectangleWrapper';
import Ellipse from 'site/components/Ellipse';
import Logo from 'site/icons/Logo.svg';
import { secondMenu, thirdMenu } from './constants';

const LinkPrimary = bindPropsHOC({ type: 'primary' })(Link);

const currentYear = new Date().getFullYear();

const linkLogo = 'https://sberauto.com';

const emailHelp = 'support@sberauto.com';

function Scooter({ theme, isMobile, isDesktop, menu }, { host }) {
  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .logo
          :global(svg)
            display block

        .journalLink
          text-decoration none
          text-transform uppercase
          color ${theme.colors.blue700}
          font ${theme.texts.bodySmall.font}
          font-weight 600

        .title
          color ${theme.colors.white}
          margin-bottom 130rem
          font-size-adjust 0.5

          :global(.mobile) &
            margin-bottom 24rem

        .pvpBtns
          justify-content space-between
      `}</style>
    </scope>
  );

  const logoSizes = isMobile ? { width: 134, height: 22 } : { width: 150, height: 24 };
  const menuTitleSize = isDesktop ? 'title4' : 'title2';
  const firstMenu = (menu?.data?.attributes?.menu_items || []).map(item => ({
    title: item.content,
    url: item.link,
  }));

  return (
    <div className='scooter'>
      <style jsx>{`
        .scooter
          position relative
          overflow hidden
          background #E9F3FF
          border-radius 64rem 64rem 0 0
          padding 67rem 45rem 105rem
          z-index 1

          :global(.mobile) &
            padding 16rem 12rem 88rem 12rem
            border-radius 20rem 20rem 0 0

          .topPart
            display flex
            justify-content space-between
            align-items center
            margin-bottom 60rem

            :global(.mobile) &
              margin-bottom 44rem

          .gridContainer
            :global(.desktop) &
              display grid
              grid-template-areas 'buttons . menu' 'divider divider divider' 'info copy-text copy-text'
              grid-template-columns 2.8fr 0.4fr 2.7fr

          &:after
          &:before
            border-radius 50%
            position absolute
            background-image radial-gradient(125% 300% at -5% -5%, #FFFFFF 24%, #209FCC 36%, #32FC82 41%, #FFDD11 65%)
            filter blur(167rem)
            transform translate3d(0, 0, 0)
            z-index -1
            inset 100rem

          &:after
            content ""

            :global(.desktop) &
              left 0
              right 0

            :global(.mobile) &
              left -50%
              right -50%

          &:before
            :global(.mobile) &
              content ""
              left -50%
              right -50%

          @media (max-width: 1280px)
            :global(.desktop) &
              grid-template-columns 3.2fr .3fr 3fr
              padding 72rem 45rem 105rem

        .journal
          height 24rem
          display flex
          align-items end
          justify-content end

        .buttons
          grid-area buttons

          :global(.mobile) &
            margin-bottom 36rem

        .menu-wrapper
          :global(.desktop) &
            display flex
            flex-direction column
            justify-content space-between
            grid-area menu

        .menu-inner-wrapper
          :global(.desktop) &
            display flex
            width 100%
            justify-content space-between
            column-gap 40rem

          :global(.mobile) &
            grid-template-columns: 1fr 1fr
            grid-template-areas  'left right1' 'left right2'
            display grid
            gap 0 12px

          > div:first-child
            grid-area left

          > div:nth-child(2)
            grid-area right1

          > div:nth-child(3)
            grid-area right2

            :global(.mobile) &
              margin-top 16rem

        .menu-title
          :global(.desktop) &
            margin-bottom 24rem

          :global(.mobile) &
            margin-bottom 16rem

        .menu
          list-style none
          padding 0
          margin 0
          font ${theme.texts.bodySmall.font}

          > li
            :global(.desktop) &
              margin-bottom 16rem

            :global(.mobile) &
              margin-bottom 12rem

        .help
          white-space nowrap

        .divider
          grid-area divider
          border-top 2px solid #fff

          :global(.desktop) &
            margin 80rem 0 56rem 0

          :global(.mobile) &
            margin 36rem 0

        .info
          display flex
          flex-direction column
          grid-area info

          :global(.desktop) &
            margin-right 40rem

        .mistake-warning
          :global(.desktop) &
            margin-bottom 16rem

          :global(.mobile) &
            margin-top 24rem
            order 2

        .copy
          :global(.mobile) &
            order 1

        .copy-text
          grid-area copy-text

          :global(.desktop) &
            margin-left 12rem

          :global(.mobile) &
            margin-top 24rem

        .hotkeys
          color ${theme.colors.aqua200}
      `}
      </style>

      <div className='topPart'>
        <Link to={linkLogo} className={scoped.wrapClassNames('logo')}>
          <Logo width={logoSizes.width} height={logoSizes.height} />
        </Link>

        {isDesktop && (
          <div className='journal'>
            <Link to='/' className={scoped.wrapClassNames('journalLink')}>
              Журнал
            </Link>
          </div>
        )}
      </div>

      <div className='gridContainer'>
        <div className='buttons'>
          <BlueRectangleWrapper>
            <Texts type='title1' className={scoped.wrapClassNames('title')}>
              Журнал<br />про <Ellipse>человека</Ellipse> за рулём
            </Texts>
            <PVPButtons
              buttonSize={isDesktop ? 'small' : 'medium'}
              {...isMobile && { className: scoped.wrapClassNames('pvpBtns') }}
            />
          </BlueRectangleWrapper>
        </div>

        <div className='menu-wrapper'>
          <div className='menu-inner-wrapper'>
            <div>
              <div className='menu-title'><Texts type={menuTitleSize}>Медиа</Texts></div>
              <Texts type='link'>
                <ul className='menu'>
                  {firstMenu.map((item, i) => (
                    <li key={i}><LinkPrimary to={item.url}>{item.title}</LinkPrimary></li>
                  ))}
                </ul>
              </Texts>
            </div>
            <div>
              <div className='menu-title'><Texts type={menuTitleSize}>Проект</Texts></div>
              <Texts type='link'>
                <ul className='menu'>
                  {secondMenu.map((item, i) => (
                    <li key={i}><LinkPrimary to={item.url}>{item.title}</LinkPrimary></li>
                  ))}
                  <li>
                    Наши контакты:
                    <br />
                    <span className='help'>
                      <LinkPrimary to={`mailto:${emailHelp}`}>{emailHelp}</LinkPrimary>
                    </span>
                  </li>
                </ul>
              </Texts>
            </div>
            <div>
              <div className='menu-title'><Texts type={menuTitleSize}>Правовые сведения</Texts></div>
              <Texts type='link'>
                <ul className='menu'>
                  {thirdMenu.map((item, i) => (
                    <li key={i}><LinkPrimary to={item.url}>{item.title}</LinkPrimary></li>
                  ))}
                </ul>
              </Texts>
            </div>
          </div>
        </div>

        <div className='divider' />

        <div className='info'>
          <div className='mistake-warning'>
            <Texts type='bodySmall'>
              Нашли опечатку? Выделите текст и нажмите <span className='hotkeys'>Ctrl+Enter</span>
            </Texts>
          </div>
          <div className='copy'>
            <Texts type='bodySmall'>
              &copy; {host}, {currentYear} г.
            </Texts>
          </div>
        </div>

        <div className='copy-text'>
          <Texts type='bodySmall'>
            Все права защищены. Полное или частичное копирование материалов Сайта в коммерческих целях разрешено только с
            письменного разрешения владельца Сайта. В случае обнаружения нарушений, виновные лица могут быть привлечены к
            ответственности в соответствии с действующим законодательством Российской Федерации
          </Texts>
        </div>
      </div>
      <scoped.styles />
    </div>
  );
}

Scooter.contextTypes = {
  host: PropTypes.string,
};

Scooter.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  menu: PropTypes.object,
};

Scooter.displayName = 'Scooter';

export { Scooter as StorybookComponent };

export default compose(
  withMenu('footer'),
  withTheme,
  withBreakpoint,
)(Scooter);
