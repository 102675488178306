import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import Section from 'site/components/Section';
import SectionHeader from 'site/components/SectionHeader';

import ArticlesFeed from 'site/components/ArticlesFeed';

import styles from './index.styl';


function NewsAndArticles(props) {
  const {
    articles: {
      topics: articlesTopics,
      count: articlesCount,
    },
    isDesktop,
  } = props;

  if (articlesTopics.length === 0) return null;

  return (
    <Section withBackground={isDesktop}>
      <div className={styles.articles}>
        <SectionHeader link='/articles' count={articlesCount}>Cтатьи</SectionHeader>
        <ArticlesFeed content={articlesTopics} />
      </div>
    </Section>
  );
}

NewsAndArticles.propTypes = {
  articles: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(NewsAndArticles);
