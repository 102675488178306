import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';

import Texts from 'core/components/Texts';
import ReadDuration from 'site/components/ReadDuration';
import Authors from 'site/components/Authors';

import styles from './index.styl';


const relationships = resolveRelationships(['authors'], {});

function Info({ content, className }) {
  const {
    read_duration: readDuration,
  } = content.attributes || {};

  const {
    authors,
  } = relationships(content) || {};

  return (
    <Texts type='bodySmall' className={cx(styles.info, className)}>
      <Authors
        authors={authors}
        showAvatar
        showOnlyFirst
        shortName
        disableLink
      />
      <ReadDuration readDuration={readDuration} />
    </Texts>
  );
}

Info.propTypes = {
  content: PropTypes.object,
  className: PropTypes.string,
};
export default Info;
