import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';
import themeSwitcher from 'core/components/themeSwitcher';

import Texts from 'core/components/Texts';
import { NegativeMobile } from 'core/components/Wrappers';

import TopicBreadcrumbs from 'site/components/TopicBreadcrumbs';
import Authors from 'site/components/Authors';
import TopicCover from 'site/components/TopicCover';
import Copyright from 'site/components/Copyright';
import ReadDuration from 'site/components/ReadDuration';


import styles from './index.styl';

const relationships = resolveRelationships(['authors', 'image'], {}, {
  image: {
    versions: {},
  },
});

function CoverMobile({ content, theme }) {
  const {
    headline,
    read_duration: readDuration,
  } = content.attributes;

  const { blue500, green300 } = theme.colors;

  const {
    authors,
    image,
  } = relationships(content);

  return (
    <NegativeMobile>
      <style jsx>{`
        .${styles.gradientBackground}
          background-image linear-gradient(35deg, ${blue500} 60%, ${green300} 107%)
      `}</style>
      <div className={styles.gradientBackground}>
        <div className={styles.header}>
          <TopicBreadcrumbs content={content} />
          {/* вот такой вот заголовок, при этом h1 спрятан в хлебных крошках */}
          <Texts
            type='accent'
            tag='h1'
            className={styles.title}
          >
            {headline}
          </Texts>
          <Texts type='bodySmall' className={styles.info}>
            <Authors
              authors={authors}
              showAvatar
              showOnlyFirst
            />
            <ReadDuration readDuration={readDuration} />
          </Texts>
        </div>
        <div className={styles.image}>
          <TopicCover
            image={image}
            headline={headline}
          />
          <Copyright className={styles.copyright} content={content} />
        </div>
      </div>
    </NegativeMobile>
  );
}

CoverMobile.propTypes = {
  content: PropTypes.object,
  theme: themePropTypes(`
    {
      colors: {
        green300,
        blue500,
      },
    }
  `),
};

export default themeSwitcher('dark')(withTheme(CoverMobile));
