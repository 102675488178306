import PropTypes from 'prop-types';
import cx from 'classnames';

import coreMarkdown from 'core/components/TopicContent/blocks/markdown';

import TopicSection from 'site/components/TopicSection';

import VisitedTopicAnchor from './VisitedTopicAnchor';

import styles from './index.styl';

export default function Markdown(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  const {
    topic: {
      content: {
        id: topicId,
      },
    },
    showTopicHeader,
    isPVP,
    lastWidgetPosition,
    lastMarkdownPosition,
    theme,
  } = parentProps;

  const { title4, body } = theme.texts;

  const currentPosition = block.position;
  const isFirstWidget = currentPosition === 0;

  const isLastMarkdownWidget = currentPosition === lastMarkdownPosition;

  const collapsed = isFirstWidget && showTopicHeader;

  return (
    <div className={cx(styles.markdown, currentPosition === lastWidgetPosition && styles._isLastWidget)}>
      <style jsx>{`
        .${styles.markdown}
          :global(.mobile) &
            :global(h2.heading)
              font ${title4.font}
              color ${title4.color}
              letter-spacing ${title4.letterSpacing || 'none'}

          :global(ol > li:has(ul))
          :global(ol > li:has(ol))
            font ${title4.font}
            color ${title4.color}
            letter-spacing ${title4.letterSpacing || 'none'}

          :global(ol > li ul)
          :global(ol > li ol)
            font ${body.font}
            color ${body.color}
            letter-spacing ${body.letterSpacing || 'none'}
      `}</style>
      <TopicSection
        disableTopRadius={isFirstWidget || collapsed}
        collapseTop={collapsed}
        className={styles.topicSection}
      >
        {coreMarkdown(block, parentProps, ctx)}

        {isLastMarkdownWidget && isPVP && <VisitedTopicAnchor topicId={topicId} />}
      </TopicSection>
    </div>
  );
}

Markdown.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
