import resolve from 'core/resolver/resolve';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import CardHorizontal from 'site/cards/CardHorizontal';
import CardVertical from 'site/cards/CardVertical';
import CardRect1 from 'site/cards/CardRect1';
import CardReview from 'site/components/Reviews/Desktop';
import CardFullwidth from 'site/cards/CardFullwidth';


export const CHINESE_ARTICLES_LIMIT = 8;

export default resolve({
  kitayskieObzory: async ({ bebopApi, kitayskieObzory, consoleError }) => {
    try {
      if (kitayskieObzory) return kitayskieObzory;

      const idsToExclude = [];

      const [kitayskieObzoryVSlidere, kitayskiyObzorOdin] = await Promise.allSettled([
        bebopApi.getTopics({
          limit: 16,
          list: 'kitayskie-obzory-v-slidere',
          sort: 'list',
          include: filterRequiredParams([CardReview], 'include'),
          fields: filterRequiredParams([CardReview], 'fields'),
        })
          .then(data => {
            const denormalizedData = denormalizeData(data);
            denormalizedData.map(({ id }) => idsToExclude.push(id));
            return denormalizedData;
          })
          .catch(consoleError('kitayskieObzoryVSlidere', [])),

        bebopApi.getTopics({
          limit: 1,
          list: 'kitayskiy-obzor-odin',
          sort: 'list',
          include: filterRequiredParams([CardVertical, CardRect1], 'include'),
          fields: filterRequiredParams([CardVertical, CardRect1], 'fields'),
        })
          .then(data => {
            const denormalizedData = denormalizeData(data);
            denormalizedData.map(({ id }) => idsToExclude.push(id));
            return denormalizedData;
          })
          .catch(consoleError('kitayskiyObzorOdin block', [])),
      ])
        .then(data => data.map(({ value }) => value));

      const otherKitayskieObzory = await bebopApi
        .getTopics({
          limit: 8,
          topic_type: 'car_review',
          tag: 'chinese-cars',
          excluded_ids: idsToExclude,
          include: filterRequiredParams([CardVertical], 'include'),
          fields: filterRequiredParams([CardVertical], 'fields'),
        })
        .then(denormalizeData)
        .catch(consoleError('otherKitayskieObzory', []));

      const count = await bebopApi
        .getTopics({
          topic_type: 'car_review',
          fields: '',
          with_filtered_count: 1,
        })
        .then(data => data?.meta.filtered_count)
        .catch(consoleError('count', 0));

      return {
        kitayskieObzoryVSlidere,
        kitayskiyObzorOdin,
        otherKitayskieObzory,
        count,
      };
    } catch (e) {
      consoleError('Error in kitayskieObzory', e);
      return {
        kitayskieObzoryVSlidere: [],
        kitayskiyObzorOdin: [],
        otherKitayskieObzory: [],
        count: 0,
      };
    }
  },

  allArticles: async ({ bebopApi, allArticles, consoleError }) => {
    try {
      if (allArticles) return allArticles;

      const idsToExclude = [];

      const kitayskayaStatya = await bebopApi
        .getTopics({
          limit: 1,
          list: 'kitayskaya-statya',
          sort: 'list',
          include: filterRequiredParams([CardHorizontal], 'include'),
          fields: filterRequiredParams([CardHorizontal], 'fields'),
        })
        .then(data => {
          const denormalizedData = denormalizeData(data);
          denormalizedData.map(({ id }) => idsToExclude.push(id));
          return denormalizedData;
        })
        .catch(() => consoleError('kitayskayaStatya block', []));

      const chineseArticles = await bebopApi
        .getTopics({
          limit: CHINESE_ARTICLES_LIMIT,
          topic_type: 'article',
          tag_slug: 'chinese-cars',
          exclude_ids: idsToExclude,
          with_filtered_count: 1,
          include: filterRequiredParams([CardFullwidth, CardHorizontal], 'include'),
          fields: filterRequiredParams([CardFullwidth, CardHorizontal], 'fields'),
        })
        .then(data => ({
          topics: denormalizeData(data),
          count: data?.meta.filtered_count,
        }))
        .catch(() => consoleError('chineseArticles block', { topics: [], count: 0 }));

      const countPromise = await bebopApi
        .getTopics({
          topic_type: 'article',
          with_filtered_count: 1,
          fields: '',
        })
        .then(data => data?.meta.filtered_count);

      return { kitayskayaStatya, chineseArticles, count: countPromise };
    } catch (e) {
      consoleError('Error in allArticles', e);
      return { kitayskayaStatya: [], chineseArticles: { topics: [], count: 0 }, count: 0 };
    }
  },

  tag: ({ bebopApi, consoleError, tag }) => {
    return tag || bebopApi
      .getTag({
        tag_slug: 'chinese-cars',
      })
      .catch(consoleError);
  },
});
