import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import bindPropsHOC from 'core/components/bindProps';

import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';

import GradientMask from 'site/components/GradientMask';

import { imageMaxWidthValidator } from 'site/utils/prop-types';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'search_result',
  'authors',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  authors: [],
  image: {
    versions: {},
  },
  search_result: {
    title: '',
    body: '',
  },
});

function CardHorizontal(props) {
  const {
    content,
    titleType,
    imageMaxWidth,
    imageRatio,
    imageVersion,
    showAuthors,
    showAltHeadline,
    borderRadius,
    lineClamp,
    gap,
  } = props;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
    search_result: {
      title: searchTitle,
      body: searchBody,
    },
    authors,
  } = relationships(content) || {};

  const authorNames = authors && authors.map(author => author.attributes.name).join(', ');

  const title = searchTitle || listHeadline || headline;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.cardHorizontal}
          grid-template-columns minmax(min-content, ${imageMaxWidth + 'rem'}) 1fr
          gap ${gap}rem

        .${styles.cover}
          border-radius ${borderRadius}

        .${styles.altHeadline}
        .${styles.headline}
          -webkit-line-clamp ${lineClamp}

        .${styles.headline}
          :global(.desktop) &
            line-height 1
      `}</style>
    </scope>
  );

  return (
    <Link
      to={link}
      className={scope.wrapClassNames(styles.cardHorizontal)}
      type='primary'
      title={title}
    >
      {versions.original && (
        <div className={scope.wrapClassNames(styles.cover)}>
          <GradientMask hideEllipsis>
            <SmartImage
              versions={versions}
              version={imageVersion}
              maxWidth={imageMaxWidth}
              aspectRatio={imageRatio}
            />
          </GradientMask>
        </div>
      )}
      <div className={scope.wrapClassNames(styles.body)}>
        <Texts type={titleType} className={scope.wrapClassNames(styles.headline)}>
          <MarkdownWrapper children={title} inline />
        </Texts>
        {showAltHeadline && (
          <Texts type='body' className={scope.wrapClassNames(styles.altHeadline)}>
            <MarkdownWrapper children={searchBody || altHeadline} inline />
          </Texts>
        )}
        {showAuthors && authorNames && (
          <Texts type='button' className={styles.info}>
            <div>Автор: {authorNames}</div>
          </Texts>
        )}
      </div>
      <scope.styles />
    </Link>
  );
}

CardHorizontal.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),

  /** Тип заголовка по схеме шрифтов в теме сайта */
  titleType: PropTypes.oneOf(['accent', 'title4']),

  /** Устанавливает максимальную ширину изображения */
  imageMaxWidth: imageMaxWidthValidator,

  /** Устанавливает соотношение сторон изображения */
  imageRatio: PropTypes.number,

  /** Переобложка изображения, которая будет использоваться в карточке вместо `original` */
  imageVersion: PropTypes.string,

  /** Показывать авторов */
  showAuthors: PropTypes.bool,

  /** Показать подзаголовок */
  showAltHeadline: PropTypes.bool,

  /** Скругление у картинки */
  borderRadius: PropTypes.string,

  /** Максимальное кол-во строк */
  lineClamp: PropTypes.number.isRequired,

  /** Отступ между картинкой и контентом */
  gap: PropTypes.number,

  /** @ignore */
  theme: PropTypes.object,
};

CardHorizontal.defaultProps = {
  titleType: 'title4',
  imageMaxWidth: 264,
  imageRatio: 1.59,
  borderRadius: '28rem',
  lineClamp: 3,
  gap: 16,
};

CardHorizontal.requiredPayloadFields = requiredPayloadFields;
CardHorizontal.requiredPayloadImports = requiredPayloadImports;

export { CardHorizontal as CardHorizontalS };
export const CardHorizontalTiny = bindPropsHOC({
  titleType: 'title4',
  imageMaxWidth: 158,
  imageRatio: 1.64,
  borderRadius: '16rem',
  gap: 12,
})(CardHorizontal);

export const CardHorizontalM = bindPropsHOC({
  titleType: 'accent',
  imageMaxWidth: 845,
  imageRatio: 1.61,
  showAltHeadline: true,
  borderRadius: '40rem',
  lineClamp: 4,
  gap: 24,
})(CardHorizontal);


export default CardHorizontal;
